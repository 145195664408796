import React from "react";

const Error = ({ field, errors, touched }) => {
  if (errors[field] && touched[field]) {
    const error =
      typeof errors[field] === "object"
        ? JSON.stringify(errors[field])
        : String(errors[field]);
    return <div style={{ color: "red", marginTop: ".5rem" }}>{error}</div>;
  } else {
    return null;
  }
};
export default Error;
