import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Error from "./Error";

const genders = [
  { value: "M", label: "男" },
  { value: "F", label: "女" },
];

const Gender = ({
  field,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => (
  <FormGroup>
    <Label htmlFor={field}>性別</Label>
    {genders.map((gender, idx) => {
      // eslint-disable-next-line
      const checked = values[field] == gender.value;
      return (
        <FormGroup check key={idx}>
          <Label check>
            <Input
              type="radio"
              name={field}
              value={gender.value}
              checked={checked}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {gender.label}
          </Label>
        </FormGroup>
      );
    })}
    <Error field={field} errors={errors} touched={touched} />
  </FormGroup>
);

export default Gender;
