import React from "react";
import { FormGroup, Label } from "reactstrap";

import Error from "./Error";
import SelectWrapper from "./SelectWrapper";

const primaries = [
  { value: 1, label: "小學一年級" },
  { value: 2, label: "小學二年級" },
  { value: 3, label: "小學三年級" },
  { value: 4, label: "小學四年級" },
  { value: 5, label: "小學五年級" },
  { value: 6, label: "小學六年級" },
];
const secondaries = [
  { value: 7, label: "中學一年級" },
  { value: 8, label: "中學二年級" },
  { value: 9, label: "中學三年級" },
  { value: 10, label: "中學四年級" },
  { value: 11, label: "中學五年級" },
  { value: 12, label: "中學六年級" },
];

const EducationLevel = ({
  field,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  placeholder,
  primary,
}) => {
  const options = primary ? primaries : secondaries;
  return (
    <FormGroup>
      <Label for={field}>年級</Label>
      <SelectWrapper
        id={field}
        field={field}
        options={options}
        value={values[field]}
        placeholder={placeholder || "請選擇年級，可輸入作篩選"}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
      />
      <Error field={field} errors={errors} touched={touched} />
    </FormGroup>
  );
};

export default EducationLevel;
