import React from "react";
import { Col, Row } from "reactstrap";

import cnhe_logo from "../images/cnhe_logo.png";
import "./Header.css";

const Header = () => {
  return (
    <>
      <Row>
        <Col md={{ size: 10, offset: 1 }}>
          <img width="100%" src={cnhe_logo} alt="logo" />
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <h1 className="header">
            年度中國歷史人物
            <br className="rwd-break-md" />
            選舉2024
          </h1>
        </Col>
      </Row>
    </>
  );
};

export default Header;
