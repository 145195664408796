import React, { PureComponent } from "react";
import { FormGroup, Label } from "reactstrap";

import Error from "./Error";
import SelectWrapper from "./SelectWrapper";

const publicEducations = [
  { value: 1, label: "小學" },
  { value: 2, label: "中學" },
  { value: 3, label: "大專或以上" },
];
const codeEducations = [
  { value: 1, label: "小學" },
  { value: 2, label: "中學" },
];
class Education extends PureComponent {
  handleFieldChange = (e, v) => {
    // reset related fields
    this.props.setFieldValue("level", "");
    this.props.setTouched(
      Object.assign(this.props.touched, { level: false, education: true })
    );
    return this.props.setFieldValue(e, v);
  };
  render() {
    const {
      field,
      values,
      errors,
      touched,
      setFieldTouched,
      placeholder,
      code,
    } = this.props;
    const options = code ? codeEducations : publicEducations;
    return (
      <FormGroup>
        <Label for={field}>教育程度</Label>
        <SelectWrapper
          id={field}
          field={field}
          options={options}
          value={values[field]}
          placeholder={placeholder || "請選擇教育程度"}
          onChange={this.handleFieldChange}
          onBlur={setFieldTouched}
        />
        <Error field={field} errors={errors} touched={touched} />
      </FormGroup>
    );
  }
}

export default Education;
