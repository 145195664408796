import React from "react";
import { FormGroup, Label, Input, FormText } from "reactstrap";
import Error from "./Error";

const OTP = ({
  field,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  prevoting,
}) => (
  <FormGroup>
    <Label htmlFor={field}>一次性密碼(OTP)</Label>
    <Input
      id={field}
      placeholder="輸入短訊收到的一次性密碼"
      value={values[field]}
      onChange={handleChange}
      onBlur={handleBlur}
      autoComplete="off"
    />
    <FormText>
      {prevoting
        ? "尚未接受投票，不會發出短訊(SMS)"
        : "請確保你輸入的手提電話能夠接收短訊(SMS)，如未能收到OTP請於30分鐘後重新領取"}
    </FormText>
    <Error field={field} errors={errors} touched={touched} />
  </FormGroup>
);

export default OTP;
