import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Error from "./Error";

const age_groups = [
  {
    value: 1,
    name: "6-11歲",
  },
  {
    value: 2,
    name: "12-17歲",
  },
  {
    value: 3,
    name: "18-24歲",
  },
  {
    value: 4,
    name: "25歲或以上",
  },
];

const AgeGroup = ({
  field,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => (
  <FormGroup>
    <Label htmlFor={field}>年齡組別</Label>
    {/* <Row forms style={{ margin: "0" }}> */}
    {age_groups.map((age_group, idx) => {
      // eslint-disable-next-line
      const checked = values[field] == age_group.value;
      return (
        <FormGroup check key={idx}>
          <Label check>
            <Input
              type="radio"
              name={field}
              value={age_group.value}
              checked={checked}
              onChange={handleChange}
              onBlur={handleBlur}
            />{" "}
            {age_group.name}
          </Label>
        </FormGroup>
      );
    })}
    {/* </Row> */}
    <Error field={field} errors={errors} touched={touched} />
  </FormGroup>
);

export default AgeGroup;
