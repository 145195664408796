import React, { PureComponent } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import memoize from "memoize-one";

import NetworkService from "../NetworkService";

class SchoolFromCode extends PureComponent {
  state = { school: "" };

  getSchool = memoize(async (code) => {
    const resp = await NetworkService.resolveCode(code);
    if (resp.ok) {
      const body = await resp.json();
      return body.school;
    } else {
      return "";
    }
  });

  async componentDidUpdate() {
    const { code, error, touched } = this.props;
    if (!touched) return;
    if (error) {
      this.setState({ school: "" });
      return;
    }

    this.setState({
      school: await this.getSchool(code),
    });
  }

  render() {
    return (
      <FormGroup>
        <Label>所屬學校</Label>
        <Input
          disabled={true}
          placeholder={"請輸入短代碼"}
          value={this.state.school}
        />
      </FormGroup>
    );
  }
}

export default SchoolFromCode;
