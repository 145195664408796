import React, { PureComponent } from "react";
import {
  Button,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap";
import Error from "./Error";

import printError from "../errors";
import NetworkService from "../NetworkService";

class Mobile extends PureComponent {
  state = {
    getOTPCalled: false,
  };

  canGetOTP = () => {
    const { dirty, errors, values } = this.props;
    if (!dirty) return false;
    const mobile = values.mobile;
    if (mobile.length && !errors.mobile) return true;
    return false;
  };

  getOTP = async () => {
    const { values } = this.props;
    const { setFieldError, setFieldTouched } = this.props;
    const resp = await NetworkService.getOTP(values.mobile);
    if (!resp.ok) {
      const body = await resp.json();
      setFieldError("otp", printError(body));
      setFieldTouched("otp", true, false); // to render error field
    }
    this.setState({ getOTPCalled: true });
  };

  render() {
    const {
      field,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      prevoting,
    } = this.props;
    const { getOTPCalled } = this.state;
    return (
      <FormGroup>
        <Label htmlFor={field}>手提電話</Label>
        <InputGroup>
          <Input
            id={field}
            placeholder="請輸入可以接收短訊的手提電話號碼"
            type="tel"
            value={values[field]}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <InputGroupAddon addonType="append">
            <Button
              type="button"
              color={getOTPCalled ? "secondary" : "success"}
              disabled={prevoting || !this.canGetOTP()}
              onClick={this.getOTP}
            >
              領取OTP
            </Button>
          </InputGroupAddon>
        </InputGroup>
        <FormText className="disclaimer">
          輸入的手提電話只供是次活動投票之用(發出OTP和確保選票的唯一性)，活動完結後會立即銷毁
          <br />
          <strong>輸入手提電話後請點擊右方「領取OTP」</strong>
        </FormText>
        <FormText>
          香港區以外手提電話號碼，請加字首國家/地區號碼：
          <br />
          內地<code>+86</code> 澳門<code>+853</code> 台灣<code>+886</code>
          <br />
          新加坡<code>+65</code> 馬來西亞<code>+60</code> 英國<code>+44</code>{" "}
          澳洲
          <code>+61</code> 加拿大/美國<code>+1</code>
        </FormText>
        <Error field={field} errors={errors} touched={touched} />
      </FormGroup>
    );
  }
}

export default Mobile;
