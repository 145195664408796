import React from "react";
import { Container } from "reactstrap";
import "./PreVotingBanner.css";

const PreVotingBanner = () => {
  return (
    <Container className="banner position-sticky text-center">
      11月2日開始接受投票
    </Container>
  );
};

export default PreVotingBanner;
